import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p12becerritoAudio from "../audio/p12becerritoAudio.m4a"
import p12vacaAudio from "../audio/p12vacaAudio.m4a"
import p12VO from "../audio/p12VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page12 extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                active: false,
                cencerro: images["p08_cencerro.png"],
            }
        }
        playAudio = (x) => {
            const audioEl = document.getElementsByClassName("audio-element")[x]
            if (!this.state.active) {

                audioEl.play()
            } else { audioEl.pause();
                audioEl.currentTime = 0; }

        }
        render() {
       return (
      <div className="main-container">
      <Link to="/Page11">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page13">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
        <div className="backContainer004">
        <StaticQuery
          query={graphql`
            query {
              imageOne: file(relativePath: { eq: "p12_I_sombraVacas.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
    render={data => (
      <Img fluid={data.imageOne.childImageSharp.fluid} />
    )}
  />
        </div>
        <div className="p12Cuentamelo"  onClick={() => this.playAudio(2)} onKeyDown={() => this.playAudio(2)}>
        <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
        </div>
        <div className="_idContainer180">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_H_vaca.png"]} alt="" />
        </div>
        <div className="_idContainer181">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_G_floresTop.png"]} alt="" />
        </div>
        <div className="_idContainer182">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_F_becerroCuerpo.png"]} alt="" />
        </div>
        <div className="_idContainer183">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_E_becerroCola.png"]} alt="" />
        </div>
        <div className="_idContainer184">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_D_becerroTop.png"]} alt="" />
        </div>
        <div className="_idContainer185">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_C_cencerro.png"]} alt="" />
        </div>
        <div className="_idContainer186">
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p12_B_becerroCabeza.png"]} alt="" />
        </div>
        <div className="_idContainer187 Basic-Text-Frame">
        <div>
        <span>Becerrito le dijo a su mamá que él también quería hacer algo.</span>
        </div>
        <span>—¿Por qué no tocas tu cencerrito? —Le sugirió Mamá Vaca</span>
        </div>
        <div className="_idContainer188" onClick={() => this.playAudio(1)}>
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4267.png"]} alt="" />
        </div>
        <div className="_idContainer189" onClick={() => this.playAudio(0)}>
        <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4268.png"]} alt="" />
        </div>
            <audio className="audio-element">
            <source src={p12becerritoAudio}></source>
             </audio>
            <audio className="audio-element">
            <source src={p12vacaAudio}></source>
             </audio>
            <audio className="audio-element">
            <source src={p12VO}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page12;
